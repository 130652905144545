//const baseUrl = 'http://15.206.0.236:8095/mykare-customer';
const baseUrl = process.env.NEXT_PUBLIC_API_DB_URL;

export const formFetcher = async (url: string, options: Object) => {
  try {
    if (options) {
      const res = await fetch(`${baseUrl}${url}`, options);
      const data = await res.json();

      return data;
    } else {
      const res = await fetch(`${baseUrl}${url}`);
      const data = await res.json();
      return data;
    }
  } catch (e) {
    console.error(e);
    return {
      error: e,
    };
  }
};
