import { formFetcher } from '@/lib/formFetcher';

export const saveEnquiry = async (postData: object) => {
  try {
    const response = await formFetcher('/enquiry/save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    return response;
  } catch (error) {
    console.error('===saveEnquiry error===', error);
  }
};
