export interface LocationData {
  id: number;
  name: string;
  status: boolean;
}

const baseUrl = process.env.NEXT_PUBLIC_API_DB_URL;

export const fetchLocationData = async (): Promise<LocationData[]> => {
  const locationUrl = (platfrom: any) => {
    switch (platfrom) {
      case 'mykareBlog':
        return `${baseUrl}/location/all`;
      case 'karePedia':
        return `${baseUrl}/location/all`;
      case 'karetripBlog':
        return `https://karetrip.com/karetrip-api/data/locations`;
    }
  };
  try {
    const response = await fetch(locationUrl(process.env.NEXT_PUBLIC_PLATFORM)); // Replace with the actual API endpoint
    const data = await response.json();
    return data as LocationData[];
  } catch (error) {
    console.error('Error fetching location data:', error);
    throw error;
  }
};
export const fetchTreatmentData = async (): Promise<LocationData[]> => {
  const treatmentUrl = (platfrom: any) => {
    switch (platfrom) {
      case 'mykareBlog':
        return `${baseUrl}/location/all`;
      case 'karePedia':
        return `${baseUrl}/location/all`;
      case 'karetripBlog':
        return `https://karetrip.com/karetrip-api/data/treatments`;
    }
  };
  try {
    const response = await fetch(
      treatmentUrl(process.env.NEXT_PUBLIC_PLATFORM)
    ); // Replace with the actual API endpoint
    const data = await response.json();
    return data as LocationData[];
  } catch (error) {
    console.error('Error fetching location data:', error);
    throw error;
  }
};
