const mykareTreatments = [
  { id: 22, name: 'Angioplasty', speciality: 'Cardiology' },
  { id: 21, name: 'Coronary artery disease', speciality: 'Cardiology' },
  { id: 24, name: 'Heart valve repair', speciality: 'Cardiology' },
  { id: 25, name: 'Key hole surgery', speciality: 'Cardiology' },
  { id: 23, name: 'Pacemaker implant', speciality: 'Cardiology' },
  { id: 35, name: 'Gynecomastia', speciality: 'Cosmetic' },
  { id: 34, name: 'Lipoma', speciality: 'Cosmetic' },
  { id: 36, name: 'Mole Removal', speciality: 'Cosmetic' },
  { id: 18, name: 'Cryopreservation', speciality: 'Infertility' },
  { id: 20, name: 'Female infertility', speciality: 'Infertility' },
  { id: 17, name: 'IUI', speciality: 'Infertility' },
  { id: 16, name: 'IVF', speciality: 'Infertility' },
  { id: 19, name: 'Male infertility', speciality: 'Infertility' },
  { id: 7, name: 'Appendicitis', speciality: 'Laparoscopy' },
  { id: 6, name: 'Gallbladder stone', speciality: 'Laparoscopy' },
  { id: 5, name: 'Hernia', speciality: 'Laparoscopy' },
  { id: 11, name: 'Burr hole surgery', speciality: 'Neurosurgery' },
  { id: 12, name: 'Craniectomy', speciality: 'Neurosurgery' },
  { id: 13, name: 'Craniotomy', speciality: 'Neurosurgery' },
  { id: 14, name: 'Deep brain stimulation', speciality: 'Neurosurgery' },
  { id: 15, name: 'Shunt surgery', speciality: 'Neurosurgery' },
  { id: 42, name: 'Cataract', speciality: 'Ophthalmology' },
  { id: 41, name: 'Lasik Surgery', speciality: 'Ophthalmology' },
  { id: 1, name: 'ACL tear', speciality: 'Orthopedics' },
  { id: 3, name: 'Disc injury', speciality: 'Orthopedics' },
  { id: 38, name: 'Hip replacement', speciality: 'Orthopedics' },
  { id: 2, name: 'Joint replacement', speciality: 'Orthopedics' },
  { id: 37, name: 'Knee Arthroscopy', speciality: 'Orthopedics' },
  { id: 39, name: 'Knee replacement', speciality: 'Orthopedics' },
  { id: 4, name: 'Rotator cuff repair', speciality: 'Orthopedics' },
  { id: 8, name: 'Anal fissure', speciality: 'Proctology' },
  { id: 9, name: 'Anal fistula', speciality: 'Proctology' },
  { id: 10, name: 'Piles', speciality: 'Proctology' },
  { id: 32, name: 'Circumcision', speciality: 'Urosurgery' },
  { id: 31, name: 'Frenuloplasty', speciality: 'Urosurgery' },
  { id: 26, name: 'Genitourinary fistula', speciality: 'Urosurgery' },
  { id: 33, name: 'Hydrocele', speciality: 'Urosurgery' },
  { id: 29, name: 'Kidney removal', speciality: 'Urosurgery' },
  { id: 30, name: 'Kidney stone', speciality: 'Urosurgery' },
  { id: 27, name: 'Kidney transplant', speciality: 'Urosurgery' },
  { id: 28, name: 'Prostate enlargement', speciality: 'Urosurgery' },
  { id: 40, name: 'Varicose Vein', speciality: 'Vascular' },
  { id: 44, name: 'Varicocele', speciality: 'Vascular' },
];

const karetripTreatments = [
  {
    id: 95,
    name: 'circumcision',
    departmentId: 15,
    speciality: 'General Surgery',
  },
  {
    id: 97,
    name: 'Piles',
    departmentId: 15,
    speciality: 'General Surgery',
  },
  {
    id: 94,
    name: 'Other Treatment',
    departmentId: 15,
    speciality: 'General Surgery',
  },
  {
    id: 96,
    name: 'Lipoma (single)',
    departmentId: 15,
    speciality: 'General Surgery',
  },
  {
    id: 98,
    name: 'Gynecomastia',
    departmentId: 15,
    speciality: 'General Surgery',
  },
  {
    id: 99,
    name: 'RIRS',
    departmentId: 14,
    speciality: 'Nephrology',
  },
  {
    id: 75,
    name: 'Chronic Kidney Disease',
    departmentId: 14,
    speciality: 'Nephrology',
  },
  {
    id: 89,
    name: 'Nephrectomy',
    departmentId: 14,
    speciality: 'Nephrology',
  },
  {
    id: 100,
    name: 'Epigastric Hernia',
    departmentId: 32,
    speciality: 'Laparoscopic Surgery',
  },
  {
    id: 101,
    name: 'Femoral Hernia',
    departmentId: 32,
    speciality: 'Laparoscopic Surgery',
  },
  {
    id: 102,
    name: 'Hiatal Hernia',
    departmentId: 32,
    speciality: 'Laparoscopic Surgery',
  },
  {
    id: 103,
    name: 'Inguinal Hernia',
    departmentId: 32,
    speciality: 'Laparoscopic Surgery',
  },
  {
    id: 104,
    name: 'Incisional Hernia',
    departmentId: 32,
    speciality: 'Laparoscopic Surgery',
  },
  {
    id: 105,
    name: 'Umbilical Hernia',
    departmentId: 32,
    speciality: 'Laparoscopic Surgery',
  },
  {
    id: 1,
    name: 'Breast Cancer Surgery',
    departmentId: 2,
    speciality: 'Oncology',
  },
  {
    id: 2,
    name: 'Medical Oncology Treatment',
    departmentId: 2,
    speciality: 'Oncology',
  },
  {
    id: 3,
    name: 'Chemotherapy',
    departmentId: 2,
    speciality: 'Oncology',
  },
  {
    id: 4,
    name: 'Prostate Cancer Surgery',
    departmentId: 2,
    speciality: 'Oncology',
  },
  {
    id: 5,
    name: 'Liver Cancer Surgery',
    departmentId: 2,
    speciality: 'Oncology',
  },
  {
    id: 6,
    name: 'Oral Cancer Treatment',
    departmentId: 2,
    speciality: 'Oncology',
  },
  {
    id: 7,
    name: 'Aortic Valve Replacement',
    departmentId: 3,
    speciality: 'Cardiology',
  },
  {
    id: 8,
    name: 'Heart Bypass Surgery (CABG)',
    departmentId: 3,
    speciality: 'Cardiology',
  },
  {
    id: 9,
    name: 'Mitral Valve Replacement',
    departmentId: 3,
    speciality: 'Cardiology',
  },
  {
    id: 10,
    name: 'Atrial Septal Defect Closure',
    departmentId: 3,
    speciality: 'Cardiology',
  },
  {
    id: 11,
    name: 'Cardiology Medicine Treatment',
    departmentId: 3,
    speciality: 'Cardiology',
  },
  {
    id: 77,
    name: 'Angioplasty',
    departmentId: 3,
    speciality: 'Cardiology',
  },
  {
    id: 12,
    name: 'Knee Replacement Surgery',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 13,
    name: 'ACL Reconstruction',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 14,
    name: 'HIP Replacement Surgery',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 15,
    name: 'Shoulder Replacement Surgery',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 16,
    name: 'Sports Medicine',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 17,
    name: 'Paediatric Orthopaedic Surgery',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 82,
    name: 'Disc Herniation',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 91,
    name: 'orthopedic',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 92,
    name: 'Ortho Spine',
    departmentId: 4,
    speciality: 'Orthopaedics',
  },
  {
    id: 18,
    name: 'Gamma Knife Treatment',
    departmentId: 1,
    speciality: 'Neurology',
  },
  {
    id: 19,
    name: 'Brain Tumour',
    departmentId: 1,
    speciality: 'Neurology',
  },
  {
    id: 20,
    name: 'Cyber Knife Treatment',
    departmentId: 1,
    speciality: 'Neurology',
  },
  {
    id: 21,
    name: 'Deep Brain Stimulation',
    departmentId: 1,
    speciality: 'Neurology',
  },
  {
    id: 22,
    name: 'Cyber dyne & TMS Therapy',
    departmentId: 1,
    speciality: 'Neurology',
  },
  {
    id: 23,
    name: 'General Neuro Surgery',
    departmentId: 1,
    speciality: 'Neurology',
  },
  {
    id: 24,
    name: 'Spinal Neuro Surgery',
    departmentId: 1,
    speciality: 'Neurology',
  },
  {
    id: 25,
    name: 'Hair Transplant',
    departmentId: 12,
    speciality: 'Cosmetics',
  },
  {
    id: 26,
    name: 'Facelift Surgery',
    departmentId: 12,
    speciality: 'Cosmetics',
  },
  {
    id: 27,
    name: 'Tummy Tuck',
    departmentId: 12,
    speciality: 'Cosmetics',
  },
  {
    id: 28,
    name: 'Rhinoplasty',
    departmentId: 12,
    speciality: 'Cosmetics',
  },
  {
    id: 29,
    name: 'Liposuction',
    departmentId: 12,
    speciality: 'Cosmetics',
  },
  {
    id: 30,
    name: 'Breast Enlargement Or Reduction',
    departmentId: 12,
    speciality: 'Cosmetics',
  },
  {
    id: 31,
    name: 'Plastic & Reconstructive Surgery',
    departmentId: 12,
    speciality: 'Cosmetics',
  },
  {
    id: 88,
    name: 'Monosplasty (Pubic Lift)',
    departmentId: 12,
    speciality: 'Cosmetics',
  },
  {
    id: 32,
    name: 'Lumbar Decompression',
    departmentId: 6,
    speciality: 'Spine surgery',
  },
  {
    id: 33,
    name: 'Scoliosis Surgery',
    departmentId: 6,
    speciality: 'Spine surgery',
  },
  {
    id: 34,
    name: 'Cervical Decompression',
    departmentId: 6,
    speciality: 'Spine surgery',
  },
  {
    id: 35,
    name: 'Lumbar Micro discectomy',
    departmentId: 6,
    speciality: 'Spine surgery',
  },
  {
    id: 36,
    name: 'Artificial Disk Replacement',
    departmentId: 6,
    speciality: 'Spine surgery',
  },
  {
    id: 37,
    name: 'Spinal Fusion',
    departmentId: 6,
    speciality: 'Spine surgery',
  },
  {
    id: 87,
    name: 'Minimally Invasive Spine Surgery',
    departmentId: 6,
    speciality: 'Spine surgery',
  },
  {
    id: 38,
    name: 'Kidney Transplant',
    departmentId: 13,
    speciality: 'Transplant',
  },
  {
    id: 39,
    name: 'Liver Transplant',
    departmentId: 13,
    speciality: 'Transplant',
  },
  {
    id: 40,
    name: 'Heart Transplant',
    departmentId: 13,
    speciality: 'Transplant',
  },
  {
    id: 41,
    name: 'Autologous Bone Marrow Transplant',
    departmentId: 13,
    speciality: 'Transplant',
  },
  {
    id: 42,
    name: 'Allogeneic Bone Marrow Transplant',
    departmentId: 13,
    speciality: 'Transplant',
  },
  {
    id: 85,
    name: 'Lung Transplant',
    departmentId: 13,
    speciality: 'Transplant',
  },
  {
    id: 43,
    name: 'IVF',
    departmentId: 9,
    speciality: 'Infertility',
  },
  {
    id: 44,
    name: 'ICSI',
    departmentId: 9,
    speciality: 'Infertility',
  },
  {
    id: 45,
    name: 'Fibroid Removal Surgery',
    departmentId: 9,
    speciality: 'Infertility',
  },
  {
    id: 46,
    name: 'IVF And Donor Eggs',
    departmentId: 9,
    speciality: 'Infertility',
  },
  {
    id: 83,
    name: 'Female Infertility',
    departmentId: 9,
    speciality: 'Infertility',
  },
  {
    id: 86,
    name: 'Male Infertility',
    departmentId: 9,
    speciality: 'Infertility',
  },
  {
    id: 47,
    name: 'Urinary tract infections (UTIs)',
    departmentId: 11,
    speciality: 'Urology',
  },
  {
    id: 48,
    name: 'Enlarged prostate gland or prostatitis',
    departmentId: 11,
    speciality: 'Urology',
  },
  {
    id: 49,
    name: 'Erectile dysfunction',
    departmentId: 11,
    speciality: 'Urology',
  },
  {
    id: 50,
    name: 'Kidney diseases and kidney stones',
    departmentId: 11,
    speciality: 'Urology',
  },
  {
    id: 51,
    name: 'Painful bladder syndrome or interstitial cystitis',
    departmentId: 11,
    speciality: 'Urology',
  },
  {
    id: 52,
    name: 'Pelvic organ prolapse',
    departmentId: 11,
    speciality: 'Urology',
  },
  {
    id: 53,
    name: 'Congenital urinary tract issues',
    departmentId: 11,
    speciality: 'Urology',
  },
  {
    id: 54,
    name: 'Sinus surgery',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 55,
    name: 'Snoring/sleep disorder surgery',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 56,
    name: 'Corrective breathing surgery',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 57,
    name: 'Tonsil removal',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 58,
    name: 'Cochlear Implant',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 59,
    name: 'Endocrine Surgery',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 60,
    name: 'General Otolaryngology',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 61,
    name: 'Head and Neck Surgery',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 62,
    name: 'Laryngology',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 63,
    name: 'Paediatric Otolaryngology',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 73,
    name: 'Nasal Tumor',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 74,
    name: 'Ear Infection(Hearing Loss)',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 90,
    name: 'Sinus Infection',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 93,
    name: 'ENT',
    departmentId: 8,
    speciality: 'ENT',
  },
  {
    id: 64,
    name: 'Colon polyps',
    departmentId: 7,
    speciality: 'Gastroenterology',
  },
  {
    id: 65,
    name: 'Colon cancer',
    departmentId: 7,
    speciality: 'Gastroenterology',
  },
  {
    id: 66,
    name: 'Celiac disease',
    departmentId: 7,
    speciality: 'Gastroenterology',
  },
  {
    id: 68,
    name: 'Gastroesophagal Reflux (GERD)',
    departmentId: 7,
    speciality: 'Gastroenterology',
  },
  {
    id: 69,
    name: 'Gallbladder disease',
    departmentId: 7,
    speciality: 'Gastroenterology',
  },
  {
    id: 70,
    name: 'Irritable bowel syndrome (IBS)',
    departmentId: 7,
    speciality: 'Gastroenterology',
  },
  {
    id: 71,
    name: 'Pancreatitis',
    departmentId: 7,
    speciality: 'Gastroenterology',
  },
  {
    id: 81,
    name: "Crohn's Disease",
    departmentId: 7,
    speciality: 'Gastroenterology',
  },
  {
    id: 67,
    name: 'Hepatitis',
    departmentId: 5,
    speciality: 'Hepatology',
  },
  {
    id: 72,
    name: 'Dental treatment',
    departmentId: 10,
    speciality: 'Dentistry',
  },
  {
    id: 78,
    name: 'Braces',
    departmentId: 10,
    speciality: 'Dentistry',
  },
  {
    id: 76,
    name: 'Internal Medicine',
    departmentId: 16,
    speciality: 'General Medicine',
  },
  {
    id: 79,
    name: 'Cataract Surgery',
    departmentId: 21,
    speciality: 'Ophthalmology',
  },
  {
    id: 80,
    name: 'Corneal Surgery',
    departmentId: 21,
    speciality: 'Ophthalmology',
  },
  {
    id: 84,
    name: 'Glaucoma Surgery',
    departmentId: 21,
    speciality: 'Ophthalmology',
  },
  {
    id: 106,
    name: 'Thyroidectomy Surgery',
    departmentId: 24,
    speciality: 'Diabetology & Endocrinology',
  },
];

export function TreatmentData(platform: string | undefined) {
  switch (platform) {
    case 'mykareBlog':
      return mykareTreatments;
    case 'karePedia':
      return mykareTreatments;
    case 'karetripBlog':
      return karetripTreatments;
  }
}
