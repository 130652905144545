import React from 'react';

export const ValidationErrorMessage = (props) => {
  return (
    <>
      <span
        className={`${props.green ? 'text-[#14c176]' : 'text-red-600'} text-xs`}
      >
        {props.children}
      </span>
    </>
  );
};
